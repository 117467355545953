export const LANGUAGES = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: '繁体中文',
    value: 'ch'
  },
  {
    label: '日本語',
    value: 'ja'
  },
  {
    label: 'Русский',
    value: 'ru'
  },
  {
    label: '한국어',
    value: 'ko'
  },
  {
    label: 'Français',
    value: 'fr'
  },
  {
    label: 'العربية',
    value: 'ar'
  }
];
